





















































import Vue from 'vue';
import Component from 'vue-class-component';
import VueI18n from 'vue-i18n';
import { Prop } from 'vue-property-decorator';

import UiTooltip from '@/components/ui/UiTooltip.vue';
import { MUT_SNACKBAR } from '@/store';

@Component({
  components: {
    UiTooltip,
  },
})
export default class UiTruncateText extends Vue {
  @Prop()
  public readonly value!: string;

  @Prop({ default: false })
  public readonly copyable!: boolean;

  @Prop({ default: 'top' })
  public readonly position!: 'top' | 'right' | 'bottom' | 'left';

  public showCopy = false;
  public isOverflowing = false;

  showSuccessSnackbar(message: string | VueI18n.TranslateResult): void {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'success',
      message: message,
    });
  }

  showErrorSnackbar(message: string | VueI18n.TranslateResult): void {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'error',
      message: message,
    });
  }

  public setShowCopy(hover: boolean) {
    this.showCopy = hover && this.copyable;
  }

  public checkOverflowing() {
    if (!this.$refs.textValue) {
      this.isOverflowing = false;
      return;
    }
    const element = this.$refs.textValue as HTMLElement;
    this.isOverflowing = element.offsetWidth < element.scrollWidth;
  }
}
