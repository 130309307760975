






















































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import CreateInventoryView from '@/components/inventory/CreateInventoryView.vue';
// report views
import CostBasisRollForwardReport from '@/components/inventory/reports/CostBasisRollForwardReport.vue';
import CostBasisRollForwardReportOld from '@/components/inventory/reports/CostBasisRollForwardReportOld.vue';
import ExpandedCostBasisReport from '@/components/inventory/reports/ExpandedCostBasisReport.vue';
import InventoryActionsReport from '@/components/inventory/reports/InventoryActionsReport.vue';
import InventoryViewReport from '@/components/inventory/reports/InventoryViewReport.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiListItem from '@/components/ui/UiListItem.vue';
import Beta from '@/components/util/Beta.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';

import { ApiSvcInventoryView } from '../../../../generated/api-svc';

export interface TabItem {
  title: string;
  value: string;
  featureFlag?: string;
  icon?: string;
  showBeta?: boolean;
}

@Component({
  components: {
    CostBasisRollForwardReport,
    CostBasisRollForwardReportOld,
    ExpandedCostBasisReport,
    InventoryActionsReport,
    CreateInventoryView,
    UiDatePicker,
    UiButton,
    WalletListNew,
    UiDataTable,
    UiListItem,
    InventoryViewReport,
    Beta,
  },
})
export default class InventoryViewReports extends BaseVue {
  @Prop({ default: null })
  public readonly currentView!: ApiSvcInventoryView | null;

  public viewReport: string | null = null;

  public listItems: TabItem[] = [
    { title: 'Cost Basis Roll Forward', value: 'cost-basis-roll-forward-old', icon: 'fa-regular fa-chart-line' },
    {
      title: 'Cost Basis Roll Forward (Expanded)',
      value: 'cost-basis-roll-forward',
      icon: 'fa-regular fa-chart-line',
      featureFlag: 'cost-basis-roll-forward-v2',
      showBeta: true,
    },
    { title: 'Actions', value: 'actions', icon: 'fa-regular fa-table-list' },
    { title: 'Reclass', value: 'je-reclass', icon: 'fa-brands fa-connectdevelop' },
    {
      title: 'Actions Journal Entry Report',
      value: 'actions-je-report',
      icon: 'fa-regular fa-receipt',
      showBeta: true,
    },
    {
      title: 'Actions Trial Balance Report',
      value: 'actions-tb-report',
      icon: 'fa-regular fa-receipt',
      showBeta: true,
    },
    {
      title: 'Sage ERP JE Import',
      value: 'sage-erp-je-import',
      icon: 'fa-regular fa-receipt',
      featureFlag: 'xrp-custom-reports',
    },
    {
      title: 'Expanded Actions Report',
      value: 'expanded-actions-report',
      icon: 'fa-regular fa-receipt',
      featureFlag: 'xrp-custom-reports',
    },
    {
      title: 'Expanded Cost Basis Report',
      value: 'expanded-cost-basis-report',
      icon: 'fa-regular fa-chart-line',
      featureFlag: 'xrp-custom-reports',
    },
  ];

  // get only items that don't have a feature flag or have a feature flag and it's enabled
  public get listActiveItems() {
    return this.listItems.filter((x) => !x.featureFlag || this.checkFeatureFlag(x.featureFlag));
  }

  public get currentItem() {
    return this.listItems.find((x) => x.value === this.viewReport);
  }

  public isBeta(viewReport: unknown) {
    if (typeof viewReport !== 'string') return false;
    const item = this.listItems.find((x) => x.value === viewReport);
    return item?.showBeta || false;
  }
}
