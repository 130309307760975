import { store } from '../store';

interface NumberFormatOptions {
  style?: string;
  locale?: string;
  decimalPlaces?: number;
  hasMinDecimals?: boolean;
  accountingNegative?: boolean;
  flipSign?: boolean;
}

const formatters: { [key: string]: Intl.NumberFormat } = {};
/*
    (this could replace the formatNumber function in the BaseVue class and the coinUtils file)
    format the number of a string using the Intl.NumberFormat API
    you can pass in a style to change the format (currency, percent, etc) default is number
    you can pass in a locale to change the language (en-US, en-GB, etc) default is local locale
    you can pass in decimalPlaces to change the number of decimal places (0, 1, 2, etc) default is no limit
*/
function format(value: number | string, options?: NumberFormatOptions): string {
  if (typeof value === 'string') {
    value = Number(value);
  }

  if (options?.flipSign && value !== 0) {
    value = value * -1;
  }

  const { style, locale, decimalPlaces, hasMinDecimals } = options ?? {};
  const key = `${locale}-${style ?? 'number'}-${decimalPlaces}`;
  let formatter = formatters[key];
  if (formatter === undefined) {
    const intlOptions = {
      style,
      maximumFractionDigits: decimalPlaces,
    } as Intl.NumberFormatOptions;

    if (hasMinDecimals) {
      intlOptions.minimumFractionDigits = decimalPlaces;
    }

    if (style === 'currency') {
      intlOptions.currency = store.state.currentOrg.baseCurrency;
    }

    formatter = new Intl.NumberFormat(locale, intlOptions);
    formatters[key] = formatter;
  }

  const f = formatter.format(value);
  if (value < 0) {
    if (options?.accountingNegative) {
      return `(${f.substring(1)})`;
    }
  }

  return f;
}

function getFormatter(defaultOptions: NumberFormatOptions) {
  return (value: number | string, options?: NumberFormatOptions) => {
    const fullOptions = {
      ...defaultOptions,
      ...options,
    };

    return format(value, fullOptions);
  };
}

export default { format, getFormatter };
