import { hasProperty } from './guards';

interface ErrorOptions {
  /**
   * If true, will not print out the error constructor name if the error is an instance (or derived instance) of `Error`
   * @example
   * stringifyError(new TypeError('foo')) // 'TypeError: foo'
   * stringifyError(new TypeError('foo'), { hideErrorName: true }) // 'foo'
   * */
  hideErrorName?: boolean;
}

/**
 * A utility function designed to dynamically serialized errors of an unknown shape/type into a coherent string.
 * @param error The error to be serialized
 * @param options An optional object containing options for the serialization
 * @returns A "best-effort" serialized error
 */
export function stringifyError(error: unknown, options?: ErrorOptions): string {
  if (typeof error === 'string') {
    return error;
  }

  switch (typeof error) {
    case 'string':
      return error;
    case 'object':
      if (Array.isArray(error)) {
        return error.map((x) => stringifyError(x, options)).join('\n');
      }
      if (hasProperty('message', error, 'string')) {
        if (hasProperty('name', error, 'string') && !options?.hideErrorName) {
          return `${error.name}: ${error.message}`;
        } else {
          return error.message;
        }
      } else if (hasProperty('toString', error, 'function')) {
        return error.toString().substring(0, 255);
      } else {
        return JSON.stringify(error).substring(0, 255);
      }
    case 'number':
    case 'bigint':
      return `Unknown error with number: ${error}`;
    case 'function':
      return `Unknown error with constructor: ${error.name}`;
    default:
      return 'Unknown error';
  }
}
