


























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class UiToggle extends Vue {
  @Prop()
  public readonly value?: boolean;

  public toggleStatus = false;

  @Watch('value', { immediate: true })
  private onValueChange(newValue: boolean) {
    this.toggleStatus = newValue;
  }

  @Watch('toggleStatus')
  private onToggleStatusChanged(newValue: boolean) {
    this.$emit('input', newValue);
  }
}
