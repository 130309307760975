<template>
  <div class="pa-1">
    <v-layout align-center justify-end class="pb-3">
      <v-menu
        offset-y
        min-width="250"
        overflow-y="auto"
        max-height="96vh"
        z-index="22"
        offset-overflow
        v-if="canCreateConnection"
      >
        <v-btn slot="activator" color="success" small> {{ $t('_connectNewAccount') }} </v-btn>
        <v-list>
          <v-list-tile
            v-for="(item, index) in enabledProviders"
            :key="index"
            :disabled="item.divider"
            @click="connect(item.id)"
            v-show="!((item.id === 'qb' || item.id === 'xero') && isConnected(item.id))"
          >
            <img v-if="item.id === 'qb'" src="C2QB_green_btn_short_default.svg" />
            <img v-if="item.id === 'xero'" src="xero-connect-blue.svg" />

            <v-list-tile-title justify="center" v-else>
              <v-layout row wrap v-if="!item.divider && item.id !== 'qb' && item.id !== 'xero'">
                <v-flex xs2><img style="height: 20px" :src="item.icon" /></v-flex>
                <v-flex xs10>{{ item.name }}</v-flex>
              </v-layout>
              <v-layout row wrap v-if="item.divider && item.id !== 'qb' && item.id !== 'xero'">
                <v-flex xs12>{{ item.name }}</v-flex>
              </v-layout>
            </v-list-tile-title>

            <v-divider v-if="item.divider"></v-divider>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-layout>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="activeConnections"
      item-key="id"
      class="elevation-3"
      hide-actions
      :loading="isLoading ? 'accent' : false"
    >
      <template slot="items" slot-scope="cxn">
        <tr>
          <td>
            <template v-if="cxn.item.syncStatus">
              <v-icon v-if="cxn.item.syncStatus.isRunning" class="fa-spin">refresh</v-icon>
              <v-icon
                v-else-if="cxn.item.syncStatus.status === 'Success' && cxn.item.syncStatus.lastSyncCompletedSEC"
                color="success"
                >check</v-icon
              >
              <v-icon
                v-else-if="cxn.item.syncStatus.status === 'NotSynced' || !cxn.item.syncStatus.lastSyncCompletedSEC"
                color="grey"
                >schedule</v-icon
              >
              <v-icon v-else-if="cxn.item.syncStatus.status === 'SuccessWithWarnings'" color="warning">warning</v-icon>
              <v-icon v-else-if="cxn.item.syncStatus.status === 'Errored'" color="error">error</v-icon>
            </template>
            <template v-else-if="cxn.item.lastSyncSEC">
              <v-icon color="success">check</v-icon>
            </template>
          </td>
          <td class="text-xs-left" width="20">
            <img style="height: 20px" :src="getProviderIcon(cxn.item.provider)" v-if="cxn.item.provider !== 'Manual'" />
            <span v-else class="tw-capitalize">{{ $t('_manual') }}</span>
          </td>
          <td class="text-xs-left" style="white-space: nowrap">
            {{ cxn.item.name ? cxn.item.name : cxn.item.provider }}
          </td>
          <td class="text-xs-left">
            <span v-if="cxn.item.isSetupComplete" class="success&#45;&#45;text">
              <v-icon color="success">check</v-icon>
              {{ $t('_complete') }}
            </span>
            <span v-else class="warning&#45;&#45;text">
              <setup-accounting-connection
                :connectionId="cxn.item.id"
                :title="getFinishSetupTitle(cxn.item.provider)"
                :refresh="refresh"
                :source="cxn.item.provider"
              />
            </span>
          </td>
          <td class="text-xs-left" style="white-space: nowrap">
            <template v-if="cxn.item.syncStatus">
              <span :class="getStatusColor(cxn.item.syncStatus.status)">
                {{ toPreferredDateTime(cxn.item.syncStatus.lastSyncCompletedSEC) }}
              </span>
            </template>
            <template v-else-if="cxn.item.lastSyncSEC">
              <span class="success--text">
                {{ toPreferredDateTime(cxn.item.lastSyncSEC) }}
              </span>
            </template>
          </td>
          <td class="tw-text-wrap">
            <template v-if="cxn.item.syncStatus">
              <ul style="color: orange">
                <li v-for="(warning, ei) in cxn.item.syncStatus.warnings" v-bind:key="`w-${cxn.item.id}-${ei}`">
                  {{ warning }}
                </li>
              </ul>
              <ul style="color: red">
                <li v-for="(error, ei) in cxn.item.syncStatus.errors" v-bind:key="`e-${cxn.item.id}-${ei}`">
                  {{ error }}
                </li>
              </ul>
              <span
                v-if="cxn.item.syncStatus.status === 'Success' && cxn.item.syncStatus.lastSyncCompletedSEC"
                class="success--text"
              >
                {{ $t('_successStatusMsg') }}
              </span>
              <span v-if="cxn.item.syncStatus.status === 'Disabled'" class="warning--text">
                {{ $t('_disabledStatusMsg') }}
              </span>
            </template>
            <template v-else-if="cxn.item.lastSyncSEC && !cxn.item.isDisabled">
              <span class="success--text"> {{ $t('_successfullySynced') }} </span>
            </template>
            <template v-else-if="!cxn.item.isDisabled">
              <i>{{ $t('_unsynced') }}</i>
            </template>
            <template v-else>
              <i>{{ $tc('_disconnect', 2) }}</i>
            </template>
          </td>
          <td class="text-xs-left" style="white-space: nowrap">
            {{ cxn.item.id ? cxn.item.id : '' }}
          </td>
          <td class="text-xs-right">
            <v-btn v-if="cxn.item.isSetupComplete && canUpdateConnection" @click="editConnection(cxn.item)">
              <v-icon class="tw-text-sm tw-pr-1">replay</v-icon>{{ $t('_updateCredentials') }}
            </v-btn>
            <v-btn
              color="Info"
              v-if="(cxn.item.provider === 'QuickBooks' || cxn.item.provider === 'Xero') && !cxn.item.isDisabled"
              @click="disconnect(cxn.item)"
              >{{ $tc('_disconnect') }}</v-btn
            >
            <!--v-btn color="Info" :disabled="cxn.item.isDisabled" @click="sync(cxn.item)">Sync</v-btn-->
          </td>
        </tr>
      </template>
    </v-data-table>

    <connect-binance ref="binanceDialog" :refresh="refresh"></connect-binance>
    <connect-bitfinex ref="bitfinexDialog" :refresh="refresh"></connect-bitfinex>
    <!-- <connect-bittrex ref="bittrexDialog" :refresh="refresh" /> -->
    <!-- <connect-ftx ref="ftxDialog" :refresh="refresh" /> -->
    <connect-anchorage ref="anchorageDialog" :refresh="refresh" />
    <connect-fireblocks ref="fireblocksDialog" :refresh="refresh" />
    <connect-prime-trust ref="primeTrustDialog" :refresh="refresh" />
    <connect-coinbase-custody ref="coinbaseCustodyDialog" :refresh="refresh" />
    <connect-coinbase-pro ref="coinbaseProDialog" :refresh="refresh"></connect-coinbase-pro>
    <connect-coinbase-prime ref="coinbasePrimeDialog" :subs="subs" :refresh="refresh" />
    <connect-coinbase-retail ref="coinbaseRetailDialog" :refresh="refresh" />
    <connect-kraken ref="krakenDialog" :refresh="refresh"></connect-kraken>
    <connect-it-bit ref="itBitDialog" :refresh="refresh"></connect-it-bit>
    <connect-bitstamp ref="bitstampDialog" :refresh="refresh"></connect-bitstamp>
    <connect-gemini ref="geminiDialog" :refresh="refresh"></connect-gemini>
    <connect-bit-go ref="bitGoDialog" :refresh="refresh"></connect-bit-go>
    <connect-paxos ref="paxosDialog" :refresh="refresh"></connect-paxos>
    <connect-ny-dig ref="nyDigDialog" :refresh="refresh"></connect-ny-dig>
    <connect-s-f-o-x ref="sfoxDialog" :refresh="refresh"></connect-s-f-o-x>
    <connect-crypto-dot-com ref="cryptoDotComDialog" :refresh="refresh"></connect-crypto-dot-com>
    <connect-circle ref="circleDialog" :refresh="refresh"></connect-circle>
    <connect-foundry ref="foundryDialog" :refresh="refresh"></connect-foundry>

    <!-- shareable edit connection dialog -->
    <edit-connection
      ref="editConnectionDialog"
      :refresh="refresh"
      :connectionId="editConnectionId"
      :connectionProvider="editConnectionProvider"
      :connectionData="editConnectionData"
    >
    </edit-connection>
  </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';

import ConnectAnchorage from '@/components/org/ConnectAnchorage';
import ConnectCircle from '@/components/org/ConnectCircle.vue';
import ConnectCoinbasePrime from '@/components/org/ConnectCoinbasePrime';
import ConnectCoinbaseRetail from '@/components/org/ConnectCoinbaseRetail.vue';
import ConnectFireblocks from '@/components/org/ConnectFireblocks.vue';
import ConnectFoundry from '@/components/org/ConnectFoundry.vue';
import { getAccountingProviderIcon } from '@/utils/accountingProviders';

import { baConfig } from '../../../config';
import { OrganizationsApi } from '../../../generated/api-svc';
import { reconnectToken } from '../../mutations/connectionMutation';
import { MUT_SNACKBAR } from '../../store';
// import { toLocalDateTime } from '../../utils/dateUtils';
import ConnectBinance from './ConnectBinance';
import ConnectBitfinex from './ConnectBitfinex';
import ConnectBitGo from './ConnectBitGo';
import ConnectBitstamp from './ConnectBitstamp';
import ConnectCoinbaseCustody from './ConnectCoinbaseCustody';
import ConnectCoinbasePro from './ConnectCoinbasePro';
import ConnectCryptoDotCom from './ConnectCryptoDotCom.vue';
import ConnectGemini from './ConnectGemini';
import ConnectItBit from './ConnectItBit';
import ConnectKraken from './ConnectKraken';
import ConnectNyDig from './ConnectNyDig';
import ConnectPaxos from './ConnectPaxos';
import ConnectPrimeTrust from './ConnectPrimeTrust';
import ConnectSFOX from './ConnectSFOX';
import EditConnection from './modals/EditConnection';
import SetupAccountingConnection from './SetupAccountingConnection';
// Terminated Connections
// import ConnectBittrex from './ConnectBittrex';
// import ConnectFtx from './ConnectFtx';

export default {
  props: [],
  components: {
    ConnectCoinbaseRetail,
    ConnectCoinbasePrime,
    ConnectSFOX,
    ConnectCoinbaseCustody,
    ConnectPrimeTrust,
    ConnectNyDig,
    ConnectPaxos,
    ConnectGemini,
    ConnectBitstamp,
    ConnectItBit,
    ConnectBitGo,
    ConnectBinance,
    ConnectCoinbasePro,
    ConnectBitfinex,
    ConnectKraken,
    ConnectAnchorage,
    ConnectFireblocks,
    ConnectCryptoDotCom,
    ConnectCircle,
    ConnectFoundry,
    SetupAccountingConnection,
    EditConnection,
    // Terminated Connections
    // ConnectBittrex,
    // ConnectFtx,
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          value: 'provider',
        },
        {
          text: this.$t('_provider'),
          align: 'left',
          value: 'logo',
        },
        {
          text: this.$t('_name'),
          align: 'left',
          value: 'logo',
        },
        {
          text: this.$t('_setupStatus'),
          value: 'isSetupComplete',
        },
        {
          text: this.$t('_lastSync'),
          align: 'left',
          value: 'lastsynced',
        },
        {
          text: this.$t('_syncNotes'),
          align: 'left',
          value: 'sync',
        },
        {
          text: this.$t('_connectionId'),
          align: 'left',
          value: 'connectionId',
        },
        {
          text: this.$tc('_action', 2),
          align: 'center',
          value: 'actions',
        },
      ],
      connections: [],
      providers: [
        {
          name: 'Exchanges',
          divider: true,
        },
        {
          id: 'binance',
          name: 'Binance',
          connection: {},
          isConnected: false,
          icon: '/binance.png',
        },
        {
          id: 'bitfinex',
          name: 'Bitfinex',
          connection: {},
          isConnected: false,
          icon: '/bitfinex.png',
        },
        // {
        //   id: 'bittrex',
        //   name: 'Bittrex',
        //   connection: {},
        //   isConnected: false,
        //   icon: '/bittrex.png'
        // },
        // {
        //   id: 'ftx',
        //   name: 'Ftx',
        //   connection: {},
        //   isConnected: false,
        //   icon: '/ftx.png',
        // },
        // {
        //   id: 'primetrust',
        //   name: 'PrimeTrust',
        //   connection: {},
        //   isConnected: false,
        //   icon: '/primetrust.png',
        // },
        // {
        //   id: 'coinbasecustody',
        //   name: 'Coinbase Custody',
        //   connection: {},
        //   isConnected: false,
        //   icon: '/coinbase-custody.png',
        // },
        {
          id: 'coinbasepro',
          name: 'Coinbase Pro/Exchange',
          connection: {},
          isConnected: false,
          icon: '/coinbasepro.png',
        },
        {
          id: 'coinbaseprime',
          name: 'Coinbase Prime',
          connection: {},
          isConnected: false,
          icon: '/coinbaseprime.png',
        },
        {
          id: 'coinbaseretail',
          name: 'Coinbase Retail',
          connection: {},
          isConnected: false,
          icon: '/coinbase-retail.png',
          // enabled: false,
        },
        {
          id: 'kraken',
          name: 'Kraken',
          connection: {},
          isConnected: false,
          icon: '/kraken.png',
        },
        // {
        //   id: 'itbit',
        //   name: 'ItBit',
        //   connection: {},
        //   isConnected: false,
        //   icon: '/itbit.png',
        // },
        {
          id: 'bitstamp',
          name: 'Bitstamp',
          connection: {},
          isConnected: false,
          icon: '/bitstamp.png',
        },
        {
          id: 'gemini',
          name: 'Gemini',
          connection: {},
          isConnected: false,
          icon: '/gemini.png',
        },
        {
          id: 'paxos',
          name: 'Paxos',
          connection: {},
          isConnected: false,
          icon: '/paxos.png',
        },
        {
          id: 'sfox',
          name: 'SFOX',
          connection: {},
          isConnected: false,
          icon: '/sfox.png',
        },
        {
          id: 'crypto.com',
          name: 'Crypto.com',
          connection: {},
          isConnected: false,
          icon: '/crypto-com.png',
          enabled: true,
        },
        {
          id: 'circle',
          name: 'Circle',
          connection: {},
          isConnected: false,
          icon: '/circle.png',
          enabled: true,
        },
        {
          id: 'foundry',
          name: 'Foundry',
          connection: {},
          isConnected: false,
          icon: '/foundry.png',
          flag: 'beta-integrations',
          enabled: true,
        },
        {
          name: 'Custodial / Wallets',
          divider: true,
        },
        {
          id: 'bitgo',
          name: 'BitGo',
          connection: {},
          isConnected: false,
          icon: '/bitgo.png',
        },
        {
          id: 'nydig',
          name: 'NyDig',
          connection: {},
          isConnected: false,
          icon: '/nydig.jpeg',
        },
        {
          id: 'anchorage',
          name: 'Anchorage',
          connection: {},
          isConnected: false,
          icon: '/anchorage.png',
        },
        {
          id: 'fireblocks',
          name: 'Fireblocks',
          connection: {},
          isConnected: false,
          icon: '/fireblocks.png',
          enabled: false,
        },
      ],
      isLoading: 0,
      remoteConnections: [],

      // edit connection props
      editConnectionId: null,
      editConnectionProvider: null,
      editConnectionData: null,
      activeConnections: [],

      // buttons with permissions check
      canCreateConnection: false,
      canUpdateConnection: false,
      subs: [],
    };
  },
  watch: {
    connections: {
      handler() {
        this.getActiveConnections();
      },
      deep: true,
    },
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            isSetupComplete
            isDisabled
            isDeleted
            syncStatus {
              status
              lastSyncCompletedSEC
              errors
              warnings
              isRunning
            }
            name
            subAccountName
          }
        }
      `,
      /* -- Added on the bitgo branch I think
            isSetupComplete
            multiWalletSyncMode
            enabledWallets
       */
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  mounted() {
    this.refresh();

    // check permissions for creating + updating connections
    this.canCreateConnection = this.checkScope(this.scopeLiterals.ConnectionsCreate);
    this.canUpdateConnection = this.checkScope(this.scopeLiterals.ConnectionsUpdate);
  },
  methods: {
    getFinishSetupTitle(provider) {
      if (provider === 'Xero') {
        return this.$t('_finishXeroSetup');
      } else if (provider === 'QuickBooks') {
        return this.$t('_finishQBSetup');
      }
    },
    getStatusColor(status) {
      if (status === 'Success') {
        return 'success--text';
      } else if (status === 'SuccessWithWarnings') {
        return 'orange--text';
      } else if (status === 'Errored') {
        return 'failure--text';
      } else {
        return '';
      }
    },
    getProviderIcon(provider) {
      return getAccountingProviderIcon(provider);
    },
    // toLocalTime(time) {
    //   if (time) {
    //     return toLocalDateTime(time);
    //   } else {
    //     return 'Never Synced';
    //   }
    // },
    refresh() {
      this.isLoading = true;

      try {
        this.editConnectionData = {};
        this.$apollo.queries.connections.refetch();

        this.getSubsidiaries();

        // get active connections
        this.getActiveConnections();
      } catch (e) {
        this.showErrorSnackbar('Problem loading connections: ' + e.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getSubsidiaries() {
      const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await orgsApi.listSubsidiaries(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        this.subs = resp.data;
      } else {
        throw new Error('Problem loading subs');
      }
    },
    async getActiveConnections() {
      if (!this.connections) return [];
      const accoutingProviders = ['Xero', 'QuickBooks', 'NetSuite', 'SageIntacct', 'Request'];
      this.activeConnections = this.connections
        .filter((m) => !m.isDeleted && !accoutingProviders.includes(m.provider))
        .map((conn) => {
          if (conn.provider === 'Foundry') {
            conn.name = conn.subAccountName ? `${conn.provider} - ${conn.subAccountName.toUpperCase()}` : conn.name;
          }
          return conn;
        });
    },
    async disconnect(item) {
      this.connections = this.connections.map((c) => (c.id === item.id ? item : c));

      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation revokeToken($orgId: ID!, $connectionId: ID!) {
            revokeToken(orgId: $orgId, connectionId: $connectionId) {
              success
              error
            }
          }
        `,
        // Parameters
        variables: {
          orgId: this.$store.state.currentOrg.id,
          connectionId: item.id,
        },
      });
      if (resp.data.revokeToken.success === true) {
        item.isDisabled = true;
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Disconnection successful.',
        });
      } else {
        const msg = 'Problem disconnecting connection';
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: msg,
        });
      }
    },
    async sync(item) {
      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $connectionId: ID!) {
            syncConnection(orgId: $orgId, connectionId: $connectionId) {
              success
              errors
            }
          }
        `,
        // Parameters
        variables: {
          orgId: this.$store.state.currentOrg.id,
          connectionId: item.id,
        },
      });
      if (resp.data.syncConnection.success === true) {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Connection Sync Initiated',
        });
      } else {
        let msg = 'Problem initiating connection sync';

        if (resp.data.errors) {
          msg = resp.data.errors.join('<br />');
        }

        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: msg,
        });
      }
    },
    async reconnect(cxnId, item) {
      if (cxnId === 'qb') {
        const variables = {
          orgId: this.$store.state.currentOrg.id,
          connectionId: item.id,
        };
        this.connections = this.connections.map((e) => {
          if (e.isDisabled) e.isDisabled = !e.isDisabled;
          return e;
        });
        const mutation = await reconnectToken;
        await this.$apollo.mutate({
          mutation,
          variables,
        });
      } else {
        console.error('Throw Error');
      }
    },
    async connect(cxnId) {
      // if (cxnId === "xero") {
      //   this.$refs.xeroDialog.show();
      // } else
      if (cxnId === 'binance') {
        this.$refs.binanceDialog.show();
      } else if (cxnId === 'bitfinex') {
        this.$refs.bitfinexDialog.show();
      } else if (cxnId === 'coinbasepro') {
        this.$refs.coinbaseProDialog.show();
      } else if (cxnId === 'bitgo') {
        this.$refs.bitGoDialog.show();
      } else if (cxnId === 'kraken') {
        this.$refs.krakenDialog.show();
      } else if (cxnId === 'itbit') {
        this.$refs.itBitDialog.show();
      } else if (cxnId === 'bitstamp') {
        this.$refs.bitstampDialog.show();
      } else if (cxnId === 'gemini') {
        this.$refs.geminiDialog.show();
      } else if (cxnId === 'paxos') {
        this.$refs.paxosDialog.show();
      } else if (cxnId === 'nydig') {
        this.$refs.nyDigDialog.show();
      } else if (cxnId === 'bittrex') {
        this.$refs.bittrexDialog.show();
      } else if (cxnId === 'ftx') {
        this.$refs.ftxDialog.show();
      } else if (cxnId === 'anchorage') {
        this.$refs.anchorageDialog.show();
      } else if (cxnId === 'primetrust') {
        this.$refs.primeTrustDialog.show();
      } else if (cxnId === 'coinbasecustody') {
        this.$refs.coinbaseCustodyDialog.show();
      } else if (cxnId === 'sfox') {
        this.$refs.sfoxDialog.show();
      } else if (cxnId === 'coinbaseprime') {
        this.$refs.coinbasePrimeDialog.show();
      } else if (cxnId === 'fireblocks') {
        this.$refs.fireblocksDialog.show();
      } else if (cxnId === 'coinbaseretail') {
        this.$refs.coinbaseRetailDialog.show();
      } else if (cxnId === 'crypto.com') {
        this.$refs.cryptoDotComDialog.show();
      } else if (cxnId === 'circle') {
        this.$refs.circleDialog.show();
      } else if (cxnId === 'foundry') {
        this.$refs.foundryDialog.show();
      } else {
        // console.log('Getting URL for redirect:', cxnId);
        const url = this.getFunctionsBaseUrl() + 'app/org/connections/connect?service=' + cxnId;
        // const options = {
        //   withCredentials: true,
        // };

        const data = {
          orgId: this.$store.state.currentOrg.id,
        };

        const resp = await axios({
          method: 'POST',
          url,
          withCredentials: true,
          data,
        });

        // const resp = await axios.post(url, data, options);
        if (resp.status === 200) {
          const body = resp.data;
          // const b = JSON.parse(body);
          window.location.href = body.connectUrl;
        } else {
          console.error(resp.data);
          return false;
        }
      }
    },
    editConnection(item) {
      // set connection id
      this.editConnectionData = item;
      this.editConnectionId = item.id;
      this.editConnectionProvider = item.provider;

      // open edit dialog
      this.$refs.editConnectionDialog.show();
    },
    isConnected(cxnId) {
      const provider = cxnId === 'qb' ? 'quickbooks' : cxnId;
      if (this.connections.length > 0) {
        const isConnect = this.connections.some(
          (m) => m.provider.toLowerCase() === provider && m.isSetupComplete && !m.isDisabled
        );
        return isConnect;
      }

      return false;
    },
  },
  computed: {
    contacts() {
      return this.$store.getters['contacts/CONTACTS'];
    },
    enabledProviders() {
      return this.providers.filter((p) => {
        if (p.enabled === undefined) {
          return true;
        }

        // add check by flag name
        const flag = p.flag ?? p.id;
        return this.checkFeatureFlag(flag, this.features);
      });
    },
  },
};
</script>
<style>
.quickbook-button {
  border: none;
  padding: 0px 5px;
  height: 28px;
}
.quickbook-button img {
  height: 29px;
}
.qb-disconnect-button {
  background: #2ca01c;
  color: #ffff;
  padding: 2px;
  border-radius: 5px;
}
@media screen and (min-width: 100px) and (max-width: 500px) {
  .v-menu__activator {
    flex-direction: column;
  }
  .quickbook-button img {
    height: 25px;
  }
}
</style>
