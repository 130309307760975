var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-tooltip',{staticClass:"tw-align-middle tw-w-inherit",attrs:{"show":_vm.isOverflowing,"text":_vm.value,"position":_vm.position,"tooltipClass":_vm.copyable ? 'tw-ml-6' : 'tw-ml-2'}},[(_vm.copyable)?_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.value),expression:"value",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(function () { return _vm.showSuccessSnackbar(_vm.$t('_clipboardSuccess')); }),expression:"() => showSuccessSnackbar($t('_clipboardSuccess'))",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(function () { return _vm.showErrorSnackbar(_vm.$t('_clipboardFail')); }),expression:"() => showErrorSnackbar($t('_clipboardFail'))",arg:"error"}],ref:"textValue",class:{
      'tw-w-full': true,
      'tw-inline-block': true,
      'tw-truncate': true,
      'tw-cursor-pointer': _vm.copyable,
      'hover:tw-text-blue-500': _vm.copyable,
    },on:{"mouseenter":function($event){_vm.checkOverflowing();
      _vm.setShowCopy(true);},"mouseleave":function($event){_vm.checkOverflowing();
      _vm.setShowCopy(false);}}},[_vm._v(_vm._s(_vm.value)+" "),(_vm.showCopy)?_c('i',{staticClass:"fa fa-clipboard tw-text-neutral-300 tw-absolute copy-icon right",class:{ overflowing: _vm.isOverflowing },attrs:{"icon":"fa-solid fa-copy"}}):_vm._e()]):_c('span',{ref:"textValue",class:{
      'tw-w-full': true,
      'tw-inline-block': true,
      'tw-truncate': true,
      'tw-cursor-pointer': _vm.copyable,
      'hover:tw-text-blue-500': _vm.copyable,
    },on:{"mouseenter":function($event){return _vm.checkOverflowing()},"mouseleave":function($event){return _vm.checkOverflowing()}}},[_vm._v(_vm._s(_vm.value)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }