
















import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiFormLabel from './UiFormLabel.vue';

@Component({ components: { UiFormLabel } })
export default class UiDatePicker extends Vue {
  /** Use via `v-model`. The value of this form element, can be either a string or a JS Date object. */
  @Prop({ default: new Date() })
  public readonly value!: Date | string;

  /** Emit native javascript date objects instead of strings for `v-model` */
  @Prop({ default: false })
  public readonly nativeDates!: boolean;

  /** Optional label to display above this form input element */
  @Prop()
  public readonly label?: string;

  /** Optional description to show below the label for this form input element */
  @Prop()
  public readonly description?: string;

  @Prop()
  public readonly disabled?: boolean;

  @Prop()
  public readonly max?: string;

  @Prop()
  public readonly min?: string;

  public instanceId = uuidv4();
}
