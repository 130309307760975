<template>
  <ba-dialog
    :save="save"
    :title="title"
    :canSave="!canSave"
    :show-button="false"
    :show="showDialog"
    :onHide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-layout row>
        <v-flex :class="hasValidation ? 'md7' : 'md12'">
          <p class="tw-px-2 tw-pb-4">
            <span class="tw-text-light">
              {{ $t('_editConnectionWarning1') }}
            </span>
            <br /><br />
            <span class="tw-font-bold">
              {{ $t('_editConnectionWarning2') }}
            </span>
          </p>
          <form ref="credentialsForm" v-if="fields && fields.length">
            <template v-for="(field, index) in fields">
              <template v-if="field.type === 'text'">
                <v-text-field
                  v-model="field.value"
                  :key="field.name + '_' + index"
                  :label="field.label"
                  :rules="field.rules || []"
                  :disabled="field.disabled"
                ></v-text-field>
              </template>
              <template v-if="field.type === 'select'">
                <v-select
                  v-model="field.value"
                  item-text="name"
                  item-value="id"
                  :key="field.name"
                  :label="field.label"
                  :items="field.items"
                  :menu-props="{ contentClass: 'first-element-bold' }"
                ></v-select>
              </template>
            </template>
          </form>
        </v-flex>
        <v-flex md5 v-if="hasValidation">
          <validation-summary
            :show="true"
            ref="connectionValidation"
            :validation-data="validationData"
            :account-type="selectedProvider.accountType"
          />

          <template v-if="hasValidation">
            <v-btn :disabled="!canValidate" @click="validate" :loading="isValidating">Validate</v-btn>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';

import ValidationSummary from '@/components/validation/AccountValidationSummary';

const DEFAULT_API_KEY_FIELD = {
  name: 'apiKey',
  label: 'Api Key:',
  type: 'text',
  value: '',
  rules: [(v) => !!v || 'Field is required'],
};

const DEFAULT_XAPI_KEY_FIELD = {
  name: 'xApiKey',
  label: 'X-API-Key:',
  type: 'text',
  value: '',
  rules: [(v) => !!v || 'Field is required'],
};

const DEFAULT_PRIVATE_KEY_FIELD = {
  name: 'privateKey',
  label: 'Private Key:',
  type: 'text',
  value: '',
  rules: [(v) => !!v || 'Field is required'],
};

const DEFAULT_API_SECRET_FIELD = {
  name: 'apiSecret',
  label: 'Api Secret:',
  type: 'text',
  value: '',
  rules: [(v) => !!v || 'Field is required'],
};

const DEFAULT_SECRET_FIELD = {
  name: 'secret',
  label: 'Secret:',
  type: 'text',
  value: '',
  rules: [(v) => !!v || 'Field is required'],
};

export default {
  components: { ValidationSummary },
  props: ['refresh', 'connectionData', 'connectionProvider', 'connectionId'],
  data() {
    return {
      apiKey: '',
      privateKey: '',
      showDialog: false,
      exchangeContactId: '',

      // @NOTE: dynamic fields here for all providers
      providersFields: {
        kraken: {
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_PRIVATE_KEY_FIELD],
          title: 'Kraken Exchange',
        },
        binance: {
          title: 'Binance Exchange',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_API_SECRET_FIELD],
        },
        bitfinex: {
          title: 'Bitfinex Exchange',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_API_SECRET_FIELD],
        },
        bitgo: {
          title: 'BitGo Custody',
          fields: [
            {
              name: 'accessToken',
              label: 'Token:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
          ],
        },
        itbit: {
          title: 'ItBit Custody',
          fields: [
            {
              name: 'userId',
              label: 'User ID:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'key',
              label: 'Key:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            DEFAULT_SECRET_FIELD,
          ],
        },
        bitstamp: {
          title: 'Bitstamp Exchange',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_SECRET_FIELD],
        },
        gemini: {
          title: 'Gemini Exchange',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_SECRET_FIELD],
        },
        paxos: {
          title: 'Paxos Custody',
          fields: [
            {
              name: 'clientId',
              label: 'Client Id:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'clientSecret',
              label: 'Client Secret:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'scope',
              label: 'Scope:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'itBitUserId',
              label: 'ItBit User ID:',
              type: 'text',
              value: '',
            },
            {
              name: 'itBitKey',
              label: 'ItBit Key:',
              type: 'text',
              value: '',
            },
            {
              name: 'itBitSecret',
              label: 'ItBit Secret',
              type: 'text',
              value: '',
            },
          ],
        },
        nydig: {
          title: 'NYDIG Custody',
          fields: [
            {
              name: 'name',
              label: 'Connection Name (Optional):',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 's3Bucket',
              label: 'S3 Bucket:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 's3Prefix',
              label: 'S3 Prefix:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
          ],
        },
        primetrust: {
          title: 'PrimeTrust',
          fields: [
            {
              name: 'jwt',
              label: 'JWT:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'accountId',
              label: 'PrimeTrust Account Id:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
          ],
        },
        anchorage: {
          title: 'Anchorage',
          fields: [DEFAULT_API_KEY_FIELD],
        },
        sfox: {
          title: 'SFOX',
          fields: [DEFAULT_API_KEY_FIELD],
        },
        coinbasepro: {
          title: 'Coinbase Pro',
          fields: [
            DEFAULT_API_KEY_FIELD,
            DEFAULT_API_SECRET_FIELD,
            {
              name: 'apiPassphrase',
              label: 'API Passphrase:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
          ],
        },
        coinbasecustody: {
          title: 'Coinbase Custody',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_API_SECRET_FIELD],
        },
        coinbaseprime: {
          title: 'Coinbase Prime',
          fields: [
            DEFAULT_API_KEY_FIELD,
            {
              name: 'passphrase',
              label: 'Passphrase:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'signingKey',
              label: 'Signing Key:',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
          ],
          accountType: 'CoinbasePrime Connection',
          validation: async () => {
            // validate coinbase prime
            const params = this.getParams();
            const vars = {
              createCoinbasePrime: {
                ...params,
                exchangeContactId: this.exchangeContactId, // used only for validation
              },
            };

            // call validation method from validation-summary component
            return await this.$refs.connectionValidation.validate(vars);
          },
        },
        coinbaseretail: {
          title: 'Coinbase Retail',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_API_SECRET_FIELD],
        },
        fireblocks: {
          title: 'Fireblocks',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_PRIVATE_KEY_FIELD],
          accountType: 'Fireblocks Connection',
          validation: async () => {
            // validate Fireblocks connection
            const params = this.getParams();
            const vars = {
              createFireblocks: {
                ...params,
                exchangeContactId: this.exchangeContactId, // used only for validation
              },
            };

            // call validation method from validation-summary component
            return await this.$refs.connectionValidation.validate(vars);
          },
        },
        ftx: {
          title: 'FTX',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_API_SECRET_FIELD],
        },
        netsuite: {
          title: 'NetSuite',
          fields: [
            {
              name: 'consumerKey',
              label: 'Consumer Key',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'consumerSecret',
              label: 'Consumer Secret',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'tokenId',
              label: 'Token Key',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            {
              name: 'tokenSecret',
              label: 'Token Secret',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
          ],
        },
        foundry: {
          title: 'Foundry Digital',
          fields: [
            DEFAULT_XAPI_KEY_FIELD,
            {
              name: 'subAccountName',
              label: 'Sub-Account Name:',
              type: 'text',
              value: '',
              class: 'tw-uppercase',
              rules: [(v) => !!v || 'Field is required'],
            },
          ],
          accountType: 'Foundry Connection',
          validation: async () => {
            // validate coinbase prime
            const params = this.getParams();
            const vars = {
              createFoundry: {
                ...params,
                exchangeContactId: this.exchangeContactId, // used only for validation
              },
            };

            // call validation method from validation-summary component
            return await this.$refs.connectionValidation.validate(vars);
          },
        },
        circle: {
          title: 'Circle',
          fields: [
            {
              name: 'name',
              label: 'Name',
              type: 'text',
              value: '',
              rules: [(v) => !!v || 'Field is required'],
            },
            DEFAULT_API_KEY_FIELD,
          ],
        },
        cryptodotcom: {
          title: 'Crypto.com',
          fields: [DEFAULT_API_KEY_FIELD, DEFAULT_API_SECRET_FIELD],
        },
      },
      selectedProvider: null,
      fields: [],
      title: '',
      isValidating: false,
      isValid: false,
      validationData: null,
      validationError: undefined,
      hasValidation: false,
    };
  },
  computed: {
    hasValidFields() {
      return this.fields.every((field) => {
        if (field.rules) {
          return !!field.value;
        } else {
          return true;
        }
      });
    },
    canSave() {
      return this.hasValidation ? this.hasValidFields && this.isValid : this.hasValidFields;
    },
    canValidate() {
      return this.hasValidation && this.hasValidFields;
    },
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.resetForm();
        if (this.$refs.connectionValidation) {
          this.$refs.connectionValidation.reset();
        }

        // load provider data
        this.loadProviderData();
      }
    },
  },
  methods: {
    loadProviderData() {
      if (!this.connectionProvider) return;

      const provider = this.providersFields[this.connectionProvider.toLowerCase()];
      this.selectedProvider = provider;
      this.fields = [...provider.fields] ?? [];
      this.hasValidation = provider.validation !== undefined;

      // set sub-account-name value
      if (this.connectionProvider === 'Foundry') {
        const subAccNameField = this.fields.find((f) => f.name === 'subAccountName');
        subAccNameField.value = this.connectionData.subAccountName;
        subAccNameField.disabled = !!this.connectionData.subAccountName;
      }

      // load title value
      this.title = this.connectionData?.name ? this.connectionData.name : provider.title ?? 'Edit Connection';
    },
    resetForm() {
      if (this.$refs.credentialsForm) {
        this.$refs.credentialsForm.reset();
      }

      if (this.fields.length) {
        this.fields.forEach((field) => (field.value = ''));
      }
    },
    hideDialog() {
      this.showDialog = false;
      this.resetForm();
    },
    show() {
      this.showDialog = true;
    },
    getParams() {
      const fieldsParams = {};
      this.fields.forEach((field) => {
        fieldsParams[field.name] = field.value;
      });
      return fieldsParams;
    },
    save() {
      const fieldsParams = this.getParams();
      const parameters = {
        orgId: this.$store.state.currentOrg.id,
        connectionId: this.connectionId,
        credentials: JSON.stringify(fieldsParams),
      };

      // refresh parent connections after completed the save
      if (this.refresh !== undefined) this.refresh();

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation UpdateConnectionTokenCredentials($orgId: ID!, $connectionId: ID!, $credentials: String!) {
              updateConnectionTokenCredentials(orgId: $orgId, connectionId: $connectionId, credentials: $credentials) {
                success
                errors
              }
            }
          `,
          // Parameters
          variables: parameters,
        })
        .then(() => {
          this.resetForm();
          if (this.refresh) {
            this.refresh();
          }
          this.showDialog = false;
        });
    },
    async validate() {
      this.isValidating = true;
      const validation = await this.selectedProvider.validation();
      this.validationData = validation;
      this.isValid = validation.success;
      this.isValidating = false;
    },
  },
};
</script>
