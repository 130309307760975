<template>
  <ba-dialog :save="save" title="Connect BitGo" :canSave="!canSave" :show-button="false" :show="showDialog">
    <v-container fluid grid-list-xl>
      <v-layout wrap align-center>
        <v-flex xs6 sm6 md6>
          <p>
            We recommend that the API credentials you are sharing with us be
            <b>read-only</b>.
          </p>
          <p>
            When setting up, use the following IP Addresses in the IP Whitelist:
            <br />104.196.183.18 <br />35.230.22.143
          </p>
          <v-text-field label="Token" v-model="token"></v-text-field>
          <v-autocomplete
            v-model="exchangeContactId"
            :items="contacts"
            :label="$t('_exchangeContact')"
            item-text="name"
            item-value="id"
            persistent-hint
          ></v-autocomplete>
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';
export default {
  props: ['org', 'refresh'],
  data() {
    return {
      token: '',
      exchangeContactId: '',
      showDialog: false,
    };
  },
  computed: {
    canSave() {
      return this.token;
    },
    contacts() {
      return this.$store.getters['contacts/ENABLED_CONTACTS'];
    },
  },
  watch: {},
  methods: {
    show() {
      this.showDialog = true;
    },
    save() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        create: {
          createBitGo: {
            token: this.token,
            exchangeContactId: this.exchangeContactId,
          },
        },
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create)
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          this.token = '';
          if (this.refresh) {
            this.refresh();
          }
          this.showDialog = false;
        });
    },
  },
};
</script>
